import React from 'react';
import ReactToPrint from 'react-to-print';
import Receipt_Custom_183 from "../components/Objects/Receipt_Custom_183";
import Receipt from "../components/Objects/Receipt";

const style = `
	@page{
		size: 2in 2.5in portrait;
		padding: 140mm;
		margin: 0mm;
		font-family: Arial,	sans-serif;
	}

	@media print {

		body {
			-webkit-print-color-adjust: exact;
			font-size: 16px;
			font-family: Arial,	sans-serif;
			margin: .5rem;
			padding: .5rem;
		}
		#printItems {
			padding-left: .25rem;
			padding-right: .25rem;
		}

		#printPrice,#printRefunded,#printQuantity {
			float:right;
		}

		#poInfo > div{
			padding-bottom: 10px;
		}

		li {
			list-style-type:none;
			text-align:right;
		}

		#hrPrint {
			color: black;
			background-color: white;
			margin-left: 50%;
		}
		#invoiceContainer{
			padding:2rem;
		}

		#invoiceTable {
			font-size: 16px;
			border-collapse: collapse;
		}

		#invoiceRow:nth-child(even) {background-color: #f2f2f2;}

		#invoiceTitle {
			color : black;
			font-weight: bold;
		}

		#centerInvoice {
			text-align: center;
			padding: 10px;
			margin: 1rem;
		}
	}
`;

const stone_inc_style = `
	@page{
		size: 2in 2.5in portrait;
		padding: 140mm;
		margin: 0mm;
		font-family: Arial,	sans-serif;
	}

	@media print {

		body {
			-webkit-print-color-adjust: exact;
			font-size: 12px;
			font-family: Arial,	sans-serif;
			margin: .5rem;
			padding: .5rem;
		}

		#printItems {
			font-size: 10px;
			padding-left: .25rem;
			padding-right: .25rem;
		}

		#printPrice,#printRefunded,#printQuantity {
			float:right;
		}

		#poInfo > div{
			padding-bottom: 10px;
		}

		li {
			list-style-type:none;
			text-align:right;
		}

		#hrPrint {
			color: black;
			background-color: white;
			margin-left: 50%;
		}

		#invoiceContainer{
			padding:2rem;
		}

		#invoiceTable, .list-unstyled, .receiptHeader {
			font-size: 10px;
			border-collapse: collapse;
		}

		#invoiceRow:nth-child(even) {background-color: #f2f2f2;}

		#invoiceTitle {
			color : black;
			font-weight: bold;
		}

		#centerInvoice {
			text-align: center;
			padding: 10px;
			margin: 1rem;
		}
	}
`;

// Wrapper around ReactToPrint that works around its pattern of passing anonymous functions in the render method
export default class Print extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			contentRef: null,
			triggerElement: () => this.props.trigger,
		}
	}

	getContentRef = (ref) => {
		if (!ref) return;

		this.setState({
			contentRef: () => ref
		})
	}



	render() {

		const {account, content, trigger, ...rest} = this.props;
		const {contentRef, triggerElement} = this.state;

		//switch for which receipt to render (AKA custom receipts here)
		let receiptStyle = style
		switch (account) {
			case 183:
				receiptStyle = stone_inc_style
				break;
			case 10:
				receiptStyle = stone_inc_style
				break;
			default:
				break;
		}

		if (!contentRef) {
			return (
				<div ref={this.getContentRef}>
					{content}
				</div>
			)
		}

		return (
			<ReactToPrint
				content={contentRef}
				trigger={triggerElement}
				// we'll need to switch case this if more accounts want custom receipts
				pageStyle={receiptStyle}
				{...rest}
			/>
		);
	}
}

export class PrintReceiptModal extends React.Component {
	render() {
		const {onClose, receiptData, data, rData, items, account} = this.props;

		//switch for which receipt to render (AKA custom receipts here)
		let shownReceipt = <Receipt
			account={account}
			data={data}
			rData={rData}
			items={items}
			receiptData={receiptData}
		/>
		switch (account) {
			case 183:
				shownReceipt = <Receipt_Custom_183
					account={account}
					data={data}
					rData={rData}
					items={items}
					receiptData={receiptData}
				/>
				break;
			case 10:
				shownReceipt = <Receipt_Custom_183
					account={account}
					data={data}
					rData={rData}
					items={items}
					receiptData={receiptData}
				/>
				break;
			default:
				break;
		}
		return (
			<div className="react-confirm-alert" style={{overflowY: "auto", height: "95vh", backgroundColor: "white"}}>
				<div className="react-confirm-alert-body">
					<Receipt
						account={account}
						data={data}
						rData={rData}
						items={items}
						receiptData={receiptData}
					/>
					<div className="react-confirm-alert-button-group">
						<div style={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
							<Print
								trigger={
									<button style={{left: '0px', marginRight: 'auto', marginLeft: "7.5rem"}} className={'btn btn-primary'}>
										Print Receipt
									</button>
								}
								content={shownReceipt}
								onAfterPrint={onClose}
								copyStyles={false}

							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
