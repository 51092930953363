import React from 'react';

import APPCONFIG from "constants/Config";
import Framework from "@beardeddevops/react.framework";
import LayawayView from "components/Containers/LayawayView"
import {Tab, Tabs,Card, CardContent} from "@material-ui/core";

import {Collections, Objects} from "components";
import {Button} from "reactstrap";
import API from "@beardeddevops/react.api";
import Types from "../../../../../constants/Types";
import {confirmAlert} from "react-confirm-alert";
import {toast} from "react-toastify";
const breadcrumbsStatic = [
	{
		name: 'Dashboard',
		url: '/dashboard',
		icon: 'dashboard',
	},
	{
		name: 'Layaways',
		url: '/layaways',
		icon: 'hourglass',
	}
];

class Layaway extends Framework.Components.View {

	constructor(props) {
		super(props);
		this.state.tab = 'details'
		this.state.remaining = 0;
	}
	handleAfterSave=()=>{
		this.setState({key:Math.random()})
	}
	handleDelete = () => {
		let model = new API.BuildClass(Types.Layaways._name, API.RequestType.DELETE);
		model.object.pkey = this.props.id;
		confirmAlert({
			title: 'Confirm Delete',
			message: (
				<span>
					This will delete all logs and entries tied to this layaway. <strong>This will not refund payments or restock items if deleted.</strong> Are you sure you want to delete this?
				</span>
			),
			buttons: [
				{
					label: 'Yes',
					onClick: async () => {
						try {
							await model.submit(null, API.RequestType.DELETE);
							this.props.history.push('/layaways');

						} catch (error) {

						}
					}
				},
				{
					label: 'No',
					onClick: () => {
					}
				}
			]
		});
	}

	handleCheckoutIncrement = () => {
		this.props.rootState.set({
			session_items_count: this.props.rootState.session_items_count === null ? 1 : 1 + this.props.rootState.session_items_count
		});
	}

	render() {
		const  {tab} = this.state;
		let breadcrumbs = breadcrumbsStatic.slice().concat([
			{
				name: this.state.name,
				url: `/layaways/${this.props.id}`,
				icon: 'edit',
			}
		]);
		return (
			<div className="container-fluid">
				<Framework.Components.PageBar breadcrumbs={breadcrumbs} history={this.props.history}>
					{this.state.remaining > 0 && <Button data-id="add-certificate" onClick={async () => {
						$.ajax({
							type: "PUT",
							url: APPCONFIG.settings.apiURL() + '/' + APPCONFIG.settings.apiVersion + '/checkouts/layaways',
							data: {
								object: JSON.stringify({
									"checkout": "",
									"layaway": this.props.id,
								}),
							},
							success: (data) => {
								API.ToastId = toast.info('Layaway payment added to cart.', {
									position: toast.POSITION.TOP_RIGHT
								});
								this.handleCheckoutIncrement();
								this.props.history.push('/checkout');
							},
							error: function (xhr, status, error) {
								let errorMessage = xhr.responseJSON.response
								API.ToastId = toast.warning(errorMessage, {
									position: toast.POSITION.TOP_CENTER
								});
							}
						});
					}
					} size={"sm"}
					                                     color="primary">
						<i className="fa fa-usd"/>&nbsp;&nbsp;Add Payment
					</Button>}
					<Button size={"sm"} color="danger" onClick={this.handleDelete}>
						<i className="fa fa-ban"/>&nbsp;&nbsp;Delete
					</Button>
				</Framework.Components.PageBar>
				<Tabs value={tab} onChange={this.handleTabChange} component={'div'}>
					<Tab label="Layaway" value="details"/>
					<Tab label="Payments" value="payments"/>
				</Tabs>
				<div style={{display: tab === 'details' ? 'block' : 'none'}}>
					<LayawayView
						key = {this.state.key}
						id={this.props.id}
						history={ this.props.history}
						setRemaining={(val) => this.setState({remaining: val})}
					/>
				</div>
				<div style={{display: tab === 'payments' ? 'block' : 'none'}}>
					<Card>
						<CardContent>
							<Collections.LayawayPayments
								key = {this.state.key}
								parent = {this.props.id}
								history={ this.props.history}
								reloadShell={this.props.reloadShell}
								reloadPage={this.refreshPage}
								reloadSelf={this.state.refresh}
							/>
						</CardContent>
					</Card>

				</div>
				<Framework.Elements.UniversalModal
					// isDev={APPCONFIG.settings.isDev()}
					parent ={this.props.id}
					locations={this.props.locations}
					name={"Layaway"}
					title={'Make Payment'}
					submitLabel={'Submit Payment'}
					object={Objects.Payment}
					history={this.props.history}
					toggle={this.state['modalPayment']}
					reportClose={this.reportClose('modalPayment')}
					afterSave={this.handleAfterSave}
					size={'lg'}
				/>

			</div>
		)
	}
}

export default Framework.Components.withRootState(Layaway);
