import React from "react";
import classNames from "classnames";
import ClipLoader from 'react-spinners/ClipLoader';

import API from "@beardeddevops/react.api";
import APPCONFIG from "../../constants/Config";

import Types from "constants/Types";
import Framework from "@beardeddevops/react.framework";
import ReactTable from "react-table";
import {Link} from "react-router-dom";
import {Card, CardContent} from "@material-ui/core";

import {Button} from "reactstrap";

import ReactToPrint from 'react-to-print';

export default class Skus extends Framework.Components.Collection {
	constructor(props) {
		let model = new API.BuildClass(Types.Skus._name, API.RequestType.READ_ALL);
		super(props, model);
		this.state.limit = 16;
		this.limit = 16;
	}

	render() {
		const {data, pages, limit, loading} = this.state;
		let columns = [
			{
				Header: "Name",
				accessor: "name",
				Cell: props => <Link to={"/skus/" + props.original.pkey}>{props.value}</Link>
			},
			{
				Header: "SKU",
				accessor: "sku",
				Cell: props =>
					<span>
						{props.value}
						<Framework.Printable.Label
							ref={el => (this['printable' + props.original.pkey] = el)}
							prefix="I" code={props.original.pkey} label={props.value+" $"+props.original.price_retail}
						/>
					</span>

			},
			{
				Header: "Inventory Group",
				id: "class",
				/** @param {Skus_Object} r */
				accessor: r => r.sku_classes.name,
				Cell: props => <Link
					to={"/skus/classes/" + props.original.sku_classes.pkey}>{props.original.sku_classes.name}</Link>
			},
			{
				Header: "Quantity",
				id: "inventories.quantity",
				accessor: r => {
					let quantities = r.inventoriess.map(inventory => {
						return inventory.quantity
					});
					return quantities.length > 0 ? quantities.reduce((prev, next) => {
						return prev + next;
					}) : ''
				},
			},
			{
				Header: "Description",
				accessor: "description",
			},
			{
				Header: "Price",
				accessor: "price_retail",
				Cell: props => <span>${props.value}</span>
			},
			{
				Header: "Cost",
				id: "price_purchase",
				accessor: r => r.price_purchase !== null ? "$" + r.price_purchase : ""
			},
			{
				Header: "Label",
				className: "button-cell",
				filterable: false,
				sortable: false,
				width: 80,
				Cell: props =>
					<ReactToPrint
						trigger={() =>
							<Button color="secondary pull-right" size={"sm"} block={true}>
								<i className={"fa fa-tag"}/>&nbsp;&nbsp;Print
							</Button>
						}
						content={() => this['printable' + props.original.pkey]}
						copyStyles={false}
						pageStyle={'@media print { body { -webkit-print-color-adjust: exact; margin: 0 } @page { margin: 0 }}'}
						bodyClass={""}
					/>
			},
			{
				Header: "",
				className: "button-cell",
				filterable: false,
				sortable: false,
				width: 40,
				Cell: props => {
					return (
						<span>
							<Link to={`skus/`+ props.original.pkey}>
								<Button color="green-meadow" size={"sm"} block={true}>
									<i className={"fa fa-pencil"}/></Button>
							</Link>
						</span>
					);
				}
			},
		];
		return (

			<Card>
				<CardContent>
					<div className="table-bar">
						<div className="d-flex">
							{this.renderSearch('Inventory')}
							{this.renderHeaderRight("Inventory Skus", columns)}
						</div>
					</div>
					<ReactTable
						key={this.state.key}
						columns={columns}
						manual // Forces table not to paginate or sort automatically, so we can handle it server-side
						data={data}
						pages={pages} // Display the total number of pages
						loading={loading} // Display the loading overlay when we need it
						loadingText="Requesting Data"
						onFetchData={this.fetchData} // Request new data when things change
						expanderDefaults={{
							sortable: true,
							resizable: true,
							filterable: false,
							width: undefined
						}}
						defaultSorted={[
							{
								id: this.model.sort,
								desc: this.model.order === 'desc'
							}
						]}
						onPageSizeChange={this.onPageSizeChange}
						showPagination={true}
						showPaginationTop={false}
						showPaginationBottom={true}
						showPageSizeOptions={true}
						defaultPageSize={limit}
						className="-striped -highlight"
					/>
					{APPCONFIG.settings.isDev() ? null :
						<div className={classNames("tabled", "loader", {'active': loading})}>
							<ClipLoader color={"#4A4A4A"} loading={loading}/>
						</div>
					}
				</CardContent>
			</Card>

		)
	}
}
